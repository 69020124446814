.highlight-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.youtube-iframe {
    height: 390px;
}

.youtube-player {
    height: 0;
    width: 640px;
    margin-bottom: 0;
}

.youtube-player-container {
    background: #FAFAFA;
    margin-bottom: 0;
}

.youtube-player-grow-anim {
    animation: youtube-player-expand 1.5s 1;
    animation-fill-mode: forwards;
}

.youtube-player-shrink-anim {
    animation: youtube-player-shrink 1.5s 1;
    animation-fill-mode: forwards;
}

@keyframes youtube-player-expand {
    100% {
        height: 390px;
        margin-bottom: 2vh;
    }

    0% {
        height: 0;
        margin-bottom: 0;
    }
}

@keyframes youtube-player-shrink {
    to {
        height: 0;
        margin-bottom: 0;
    }
    from {
        height: 390px;
        margin-bottom: 2vh;
    }
}