@media only screen and (max-width: 900px) {
    .scorecard {
        border-width: 5px;
    }

    .scorecard-main {
        position: relative;
        width: 100%;
        height: 15vw;
        left: 0;
        top: 0;
    }

    .scorecard-seal {
        width: 11vw;
        height: 11vw;
    }

    .scorecard-text {
        width: 25vw;
        font-size: 7vw;
    }
}