.league-selector-icon {
    transform: scale(.2);
}

.league-selector-toggle-group {
    height: 7vh;
}

.league-selector-button {
    width: 15vw;
}

.league-group-divider {
    margin: 1vh 0vh 2vh 0vh !important;
}

.league-selector-container {
    max-height: 8vh;
    margin-top: .5vh;
    margin-bottom: .5vh;
}