.title-header {
}

.title {
    font-size: 10vh;
    width: 95vw;
    font-weight: bold;
    background: var(--gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 0;
    margin-top: 2vh;
}

.subtitle {
    font-size: 1em;
    color: black;
}