.date-selector {
    align-content: center;
    height: 7vh;
    align-items: center;
    margin-top: 1vh;
    margin-bottom: 1vh;
    z-index: 10;
    min-width: 75vw;
}

.date-selector-text {
    color: black;
    margin-bottom: 0;
    line-height: 1;
}

.date-selector-button {
    line-height: 1;
}

.date-selector-picker {
    min-width: 200px;
}

.date-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 75vw;
    max-height: 7vh;
}