.scorecard-group-cards {
    overflow: auto;
    overflow-x: hidden;
}

.scorecard-group-empty-text {
    color: black;
}

.scorecard-break {
    height: 2vh;
}