.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-width: 100vw;
  background-color: #FAFAFA;
  align-items: center;
  font-size: calc(10px + 2vmin);
  justify-content: flex-start;

  --primary: #2662ac; /* #2662ac; #66a3ed */
  --secondary: #b11d25; /* #b11d25; #e66e75 */
  --gradient: linear-gradient(45deg, var(--primary) 30%, var(--secondary) 70%);

  --primary-featured: #f6d365;
  --secondary-featured: #fda085;
  --featured-gradient: linear-gradient(45deg, var(--primary-featured) 30%, var(--secondary-featured) 70%);
}

.App-header {
  height: 13vh;
  background-color: #FAFAFA;
}

.App-body {
  height: 87vh;
  background-color: #FAFAFA;
}