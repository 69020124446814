.scorecard {
    position: relative;
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    overflow-y: hidden;
    background: #FAFAFA;
}

.scorecard-regular {
    border-width: 10px;
    border-style: solid;
    border-image: var(--gradient) 1;
    border-radius: 2vw;
}

.scorecard-featured {
    border-width: 10px;
    border-style: solid;
    border-image: var(--featured-gradient) 1;
    border-radius: 2vw;
}

.scorecard-main {
    position: relative;
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
}

.scorecard-seal {
    transform-origin: 50% 50%;
    width: 7vh;
    height: 7vh;
}

.scorecard-seal-h {
   margin-left: 2vw;
}

.scorecard-seal-a {
    margin-right: 2vw;
}

.scorecard-highlight-icon-container-regular {
    border-radius: 10px;
    transform-origin: 50% 50%;
    font-size: .8em;
    margin: auto;
    padding: 1%;

    background: var(--gradient);
}

.scorecard-highlight-icon-container-featured {
    border-radius: 10px;
    transform-origin: 50% 50%;
    font-size: .8em;
    margin: auto;
    padding: 1%;

    background: var(--featured-gradient);
}

.scorecard-highlight-icon {
    color: white;
}

.scorecard-fixture-info-box {
    color: black;
    border-radius: 10px;
    transform-origin: 50% 50%;
    font-size: .8em;
    margin: auto;
    padding: 1%;

    background: #FAFAFA;
    border-width: 3px;
    border-style: solid;
    border-image: var(--gradient) 1;
}

.scorecard-fixture-info-text {
    padding: 0;
    margin: 0;
    line-height: 1;
}

.scorecard-text-content {
    flex-grow: 2.5;
    display: flex;
    flex-direction: row;
}

.scorecard-text {
    color: black;
    font-weight: bold;
    margin: auto;
    width: 33vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}