@media only screen and (max-width: 900px) {
    .youtube-player {
        height: 0;
        width: 300px;
    }

    .highlight-container {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .youtube-player-container {
        height: 0;
        width: 300px;
        position: relative;
        margin-bottom: 0;
    }

    @keyframes youtube-player-expand {
        from {
            height: 0;
            margin-bottom: 0;
        }
        to {
            height: 169px;
            margin-bottom: 2vh;
        }
    }

    @keyframes youtube-player-shrink {
        to {
            height: 0;
            margin-bottom: 0;
        }
        from {
            height: 169px;
            margin-bottom: 2vh;
        }
    }
}