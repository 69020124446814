@media only screen and (max-width: 900px) {
    .App-header {
        height: 8vh;
        max-height: 10vh;
        overflow-y: clip;
    }

    .App-body {
        height: 90vh;
    }
}